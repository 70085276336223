import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Parser from "html-react-parser"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Commun/Banner"
import Section from "../components/elements/Section"
import globalVariables from "../components/globalVariables"

const Wrapper = styled.div`
  padding: 1.5rem;
  background: white;
  margin: 0 20px;
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.headerColor};
  }
  ul,
  p {
    margin-bottom: 2rem;
  }
  li,
  p {
    color: ${props => props.theme.bodyColor};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin: 0 70px;
    padding: 2rem;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    margin: 0 100px;
    padding: 4rem;
  }
`

const MentionsLegales = ({ data }) => {
  const prismicData = getPrismicData(data)
  return (
    <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
      <SEO title="Mentions légales" />
      <Banner
        title="Mentions Légales"
        imageBanner={data.banner.childImageSharp.fluid}
        imageHeight="350px"
        imageMobileHeight="350px"
        isfirstBlock
        marginBottom="0"
        objectPosition="center 30% !important"
      />
      <Section color="greyLighter">
        <Wrapper>{Parser(prismicData.contenu)}</Wrapper>
      </Section>
    </Layout>
  )
}

export default MentionsLegales

export const query = graphql`
  query {
    prismicMentionsLegales {
      data {
        contenu {
          html
        }
      }
    }

    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    banner: file(relativePath: { eq: "mentions.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
function getPrismicData(data) {
  return {
    contenu: data.prismicMentionsLegales.data.contenu.html,
  }
}
